/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/uikit@3.21.12/dist/js/uikit-icons.min.js
 * - /npm/jquery-validation@1.21.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.21.0/dist/localization/messages_de.min.js
 * - /npm/flatpickr@4.6.13/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.13/dist/l10n/de.min.js
 * - /npm/moment@2.30.1/moment.min.js
 * - /npm/jquery-throttle-debounce@1.0.0/jquery.ba-throttle-debounce.min.js
 * - /npm/simplebar@6.2.7/dist/simplebar.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
